/* eslint-disable no-unused-vars */
/* eslint-disable no-new */

import '@dev/scss/main.scss';
import '@modules/prototype.settings';
import * as BrowserReject from 'browser-reject';
import svg4everybody from 'svg4everybody';
import WOW from 'wow.js/dist/wow';
import smoothscroll from 'smoothscroll-polyfill';
import '@modules/Accordeon';
import Popup from '@modules/Popup';
import formSender from '@modules/FormSender';
import InputFocus from '@modules/InputFocus';
import SlidingTitle from '@modules/SlidingTitle';
import '@modules/mask';
import {
  invertChange, checkScroll, delegate, scrollTo,
} from '../Utils';

function importAll(r) {
  return r.keys().map(r);
}

window.onload = function () {
  BrowserReject.reject({
    reject: {
      all: false,
      msie: 11,
    },
    display: ['chrome', 'firefox', 'opera'],
    header: 'Знаете ли вы что ваш браузер устарел?',
    imagePath: './assets/img/reject/',
    paragraph1:
            'Ваш браузер устарел, и не может нормально отображать наш сайт. Список самых популярных браузеров вы можете найти ниже',
    paragraph2: 'Просто нажмите на иконку чтобы перейти на страницу загрузки',
    close: false,
    closeESC: false,
  });
};

new Popup({
  popupClass: 'popup_menu',
  compensateClass: 'popup_menu_blocked',
  isMainButton: true,
  callback: invertChange,
});

new Popup({
  popupClass: 'popup_callback',
  openClass: 'callback',
});

const popupOverlay = new Popup({
  popupClass: 'popup_done',
  openClass: 'callback_done',
});
window.popupOverlay = popupOverlay;

const slidingTitles = document.querySelectorAll('.section_title');
if (slidingTitles) {
  slidingTitles.forEach((item) => {
    new SlidingTitle(item);
  });
}

window.focusedInputs = [];
document.querySelectorAll('.form_input').forEach((parent, idx) => {
  const inputClass = new InputFocus(parent.querySelector('input, textarea'), parent);
  window.focusedInputs[idx] = inputClass;
});

document.addEventListener('DOMContentLoaded', () => {
  checkScroll();
});

delegate(document, 'click', '.scrollto', function (e) {
  e.preventDefault();
  scrollTo(this);
});

delegate(document, 'submit', '.form', function (e) {
  e.preventDefault();
  formSender(this);
});

// При вызове попапа с меню происходит небольшой скролл, детектим
let measure = 0;
window.addEventListener('scroll', () => {
  let currentMeasure = 0;
  currentMeasure += 1;

  if (Math.abs(measure - currentMeasure) > 2) {
    checkScroll();
    measure = 0;
  }

  measure += 1;
});

const menuBars = document.querySelectorAll('.menu_item_bar');
if (document.body.clientWidth <= 1024) {
  menuBars.forEach((item) => {
    item.classList.add('accordeon_btn');
  });
}

if (document.body.clientWidth > 1024) {
  menuBars.forEach((item) => {
    item.classList.add('accordeon_hover');
  });
}

importAll(require.context('../../sprite/', false, /\.svg$/));

if (document.body.clientWidth > 1024) {
  const wow = new WOW();
  wow.init();
}

svg4everybody();
smoothscroll.polyfill();
