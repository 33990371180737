import { csrfToken } from '@dev/js/constants';
import { fetchJSON } from '../Utils';

const errorClass = 'form_input--error';

const setErrors = (data, form) => {
  const errors = JSON.parse(data.errors);

  Object.keys(errors).forEach((item) => {
    const input = form.querySelector(`input[name="${item}"]`);
    const parentWrapper = input.parentNode;

    if (input) {
      parentWrapper.setAttribute('data-error', errors[item]);
      parentWrapper.classList.add(errorClass);
    }
  });
};

const setDone = (form) => {
  const errorsInputs = form.querySelectorAll(`.${errorClass}`);

  errorsInputs.forEach((item) => {
    item.removeAttribute('data-error');
    item.classList.remove(errorClass);
  });

  form.reset();

  if (window.popupOverlay) {
    window.popupOverlay.openPopup();
  }

  if (window.focusedInputs) {
    Object.keys(window.focusedInputs).forEach((item) => {
      window.focusedInputs[item].changeActive(false);
    });
  }
};

const formSender = (form) => {
  const thisForm = form;
  const formValues = new FormData(thisForm);
  const buttonSubmit = thisForm.querySelector('button[type="submit"]');
  const buttonText = buttonSubmit.textContent;

  buttonSubmit.disabled = true;
  buttonSubmit.textContent = 'Отправка';
  formValues.append('csrf_token', csrfToken);

  fetchJSON(window.location.href, {
    method: 'POST',
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
    },
    body: formValues,
  }).then((data) => {
    if (data.success) {
      setDone(thisForm);
    } else {
      setErrors(data, thisForm);
    }

    buttonSubmit.textContent = buttonText;
    buttonSubmit.disabled = false;
  });
};

export default formSender;
