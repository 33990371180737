import { addAutoResize } from '../Utils';

class InputFocus {
  constructor(element, parent) {
    this.element = element;
    this.active = false;
    this.parent = parent;
    this.init();
  }

  init() {
    if (this.element.localName === 'textarea') {
      addAutoResize(this.element);
    }

    this.element.addEventListener('input', () => {
      if (this.element.value.length > 0) {
        this.changeActive(true);
      }
    });

    this.element.addEventListener('focus', () => {
      this.changeActive(true);
    });

    this.element.addEventListener('blur', () => {
      if (this.element.value.length > 0) {
        return;
      }
      this.changeActive(false);
    });

    this.element.addEventListener('change', () => {
      if (this.element.value.length > 0) {
        return;
      }
      this.changeActive(false);
    });
  }

  changeActive(bool) {
    this.active = bool;
    this.parent.classList.toggle('active', bool);
  }
}

export default InputFocus;
