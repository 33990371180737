export default class Popup {
  constructor(config) {
    this.config = {
      ...{
        open: false,
        popupClass: 'popup',
        popupActiveClass: 'active',
        openClass: 'popup_open',
        closeClass: 'popup_close',
        compensateClass: 'blocked',
        isMainButton: false,
        callback: (bool) => bool,
      },
      ...config,
    };
    this.active = this.config.open;
    this.popup = document.querySelector(`.${this.config.popupClass}`);
    this.init();
  }

  init() {
    document.querySelectorAll(`.${this.config.openClass}`).forEach((element) => {
      element.addEventListener('click', () => {
        this.action(element);
      });
    });

    this.popup.querySelectorAll(`.${this.config.closeClass}`).forEach((element) => {
      element.addEventListener('click', () => {
        this.action(element);
      });
    });

    if (this.checkStatus()) {
      this.openPopup();
    }
  }

  action(btn) {
    if (this.config.isMainButton) {
      btn.classList.toggle('active', !this.config.open);
    }

    if (btn.getAttribute('data-input')) {
      const val = btn.getAttribute('data-input');
      const input = btn.getAttribute('data-inputname');

      this.popup.querySelector(`input[name="${input}"]`).value = val;
    }

    if (this.config.open) {
      this.closePopup();
      return;
    }

    this.openPopup();
  }

  checkStatus() {
    return this.active;
  }

  openPopup() {
    document.querySelector('body').classList.add(this.config.compensateClass);
    this.popup.parentElement.classList.add(this.config.compensateClass);
    this.popup.classList.add(`${this.config.popupActiveClass}`);
    this.config.callback(true);
    this.config.open = true;
  }

  closePopup() {
    document.querySelector('body').classList.remove(this.config.compensateClass);
    this.popup.parentElement.classList.remove(this.config.compensateClass);
    this.popup.classList.remove(`${this.config.popupActiveClass}`);
    this.config.callback(false);
    this.config.open = false;
  }
}
