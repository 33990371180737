import IMask from 'imask';

window.maskObjects = {};
document.querySelectorAll('[type="tel"]').forEach((input) => {
  const mask = IMask(input, {
    mask: '+{7} (000) 000-00-00',
    lazy: true,
    prepare(str) {
      if (this.unmaskedValue.length === 0 && str === '8') {
        return '';
      }
      return str;
    },
  });
  input.addEventListener('change', (e) => {
    if (mask.unmaskedValue.length < 11) {
      e.target.value = '';
    }
  });
  if (mask) {
    window.maskObjects[input.getAttribute('id')] = mask;
  }
});
