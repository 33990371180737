import { isElementInViewport } from '../Utils';

export default class SlidingTitle {
  constructor(element) {
    this.active = true;
    this.element = element;
    this.elementClass = element.className;
    this.wrapper = null;
    this.elements = null;
    this.timer = 10;
    this.wrapperWidth = 0;
    this.isPositive = true;
    this.offset = 0;
    this.offsetMeasure = 0.5;
    this.startInterval = null;

    this.init();
  }

  init() {
    const parent = this.element.parentNode;
    const wrapper = document.createElement('div');
    const container = document.createElement('div');

    this.element.classList.add('slide-title');
    wrapper.setAttribute('class', 'title_wrapper flex');
    container.setAttribute('class', 'title_container z5');

    parent.insertBefore(container, this.element);
    container.append(wrapper);
    wrapper.append(this.element);

    let i = 0;
    while (i < 4) {
      wrapper.append(this.element.cloneNode(true));
      i += 1;
    }

    this.wrapper = wrapper;
    this.elements = this.wrapper.querySelectorAll('.slide-title');
    this.wrapperWidth = wrapper.clientWidth;
    this.containerLeft = Math.floor(container.getBoundingClientRect().left);
    this.elemOffset = this.elements[0].clientWidth;
    this.offset = -this.elemOffset;

    this.setSlide();
    this.scrollListener();
  }

  scrollListener() {
    let measure = 0;
    let timer;

    // it isn't beautiful i know...
    const offSpeed = () => {
      setTimeout(() => {
        this.offsetMeasure = 1;
      }, 100);

      setTimeout(() => {
        this.offsetMeasure = 0.7;
      }, 200);

      setTimeout(() => {
        this.offsetMeasure = 0.5;
      }, 300);
    };

    document.addEventListener('scroll', () => {
      const windowMeasure = window.pageYOffset;

      if (document.body.clientWidth > 1024) {
        this.offsetMeasure = 1.2;

        if (timer !== undefined) {
          clearInterval(timer);
        }

        timer = setTimeout(() => {
          offSpeed();
        }, 50);
      }

      if (measure > windowMeasure) {
        this.isPositive = true;
      }

      if (measure < windowMeasure) {
        this.isPositive = false;
      }

      if (!isElementInViewport(this.wrapper)) {
        this.active = false;
        this.offset = -this.elemOffset;
        this.setSlide(true);
      }

      if (!this.active && isElementInViewport(this.wrapper)) {
        this.active = true;
        this.setSlide();
      }

      measure = windowMeasure;
    });
  }

  setSlide(bool) {
    if (bool) {
      clearInterval(this.startInterval);
      return;
    }

    const transformate = () => {
      this.wrapper.style.transform = `translateX(${this.offset}px)`;
      this.checkSide();
    };

    this.startInterval = setInterval(transformate, this.timer);
  }

  checkSide() {
    let currentElement = null;
    let coordElement = null;
    let idx = 0;
    let setOffset = 0;

    if (this.isPositive) {
      idx = this.elements.length - 4;
    } else {
      setOffset = -this.elemOffset;
    }

    currentElement = this.elements[idx];
    coordElement = Math.floor(currentElement.getBoundingClientRect().left);

    if (coordElement === this.containerLeft) {
      this.offset = setOffset;
      return;
    }

    if (this.isPositive) {
      this.offset -= this.offsetMeasure;
      return;
    }

    this.offset += this.offsetMeasure;
  }
}
