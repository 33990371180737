import { delegate } from '../Utils';

const clearAccordeon = (activeClass = 'active') => {
  const element = document.querySelector(`.accordeon_item.${activeClass}`);

  if (!element) {
    return;
  }

  const subButton = element.querySelector('.accordeon_subbtn');
  const hidden = element.querySelector('.accordeon_hidden');

  element.classList.remove(activeClass);

  if (subButton) {
    subButton.classList.remove(activeClass);
  }

  hidden.style.maxHeight = '0';
};

const startAccordeon = (el, activeClass = 'active') => {
  const element = el.closest('.accordeon_item');
  const hidden = element.querySelector('.accordeon_hidden');
  const wrapper = element.querySelector('.accordeon_wrapper');
  const subButton = element.querySelector('.accordeon_subbtn');
  const wrapperHeight = wrapper.offsetHeight;

  if (element.classList.contains(activeClass)) {
    clearAccordeon();
    return;
  }

  clearAccordeon();
  element.classList.add(activeClass);

  if (subButton) {
    subButton.classList.add(activeClass);
  }

  hidden.style.maxHeight = `${wrapperHeight}px`;
};

delegate(document, 'click', '.accordeon_btn', (e) => {
  const { target } = e;

  if (target.localName === 'a') {
    e.preventDefault();
  }

  startAccordeon(target);
});

delegate(document, 'mouseover', '.accordeon_hover', ({ target }) => {
  startAccordeon(target, 'hovered');
});

const hoveredItems = document.querySelectorAll('.accordeon_item');
hoveredItems.forEach((item) => {
  item.addEventListener('mouseleave', ({ target }) => {
    if (target.classList.contains('hovered')) {
      clearAccordeon('hovered');
    }
  });
});
